<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 个人中心
 * @Date: 2020-12-23 10:49:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-11 16:20:45
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/index.vue
-->
<template>
  <div class="user_content">
    <div class="user_init">
      <div class="user_head" @click="perinfo">
        <img :src="userarvr" alt="">
      </div>
      <div class="user_name">
        <div class="user_na" @click="perinfo">
          <div>{{ username }}</div>
          <div class="user_vpi">
            <span>{{ levelText }} </span>
            <span><img :src="memberInfo.icon"></span>
          </div>
        </div>
        <div class="user_id">
          <span>悦淘号:</span>
          <span id="ordernumber"> {{ codeFand }} </span>
          <span class="copy" @click="copyCode()">复制</span>
        </div>
        <!-- <div class="Sign_in">
          <span><img src="@/assets/images/Signin.png" alt=""></span>
          <span>每日签到</span>
        </div> -->
      </div>
      <div class="user_information">
        <div class="information_s" @click="setup">
          <img src="@/assets/images/setup.png" alt="">
          <span>设置</span>
        </div>
        <!-- <div class="information_s">
          <img src="@/assets/images/inxm.png" alt="">
          <span>悦消息</span>
        </div> -->
      </div>
    </div>
    <div class="upgrade" @click="member" />
    <div class="bill">
      <div class="bill_item" @click="isshow">
        <div>{{ usedMoney }}</div>
        <div>我的钱包(元）</div>
      </div>
      <div class="bill_item" @click="isshow">
        <div>{{ ico }}</div>
        <div>我的悦豆</div>
      </div>
      <div class="bill_item">
        <div>{{ saveMoney }}</div>
        <div>累计省钱(元）</div>
      </div>
      <div class="bill_item">
        <div>{{ leftCardNum }}</div>
        <div>剩余卡数</div>
      </div>
    </div>
    <div class="user_tool">
      <van-grid :border="false" :column-num="4">
        <van-grid-item @click="myorder">
          <img class="tool_icon" src="@/assets/images/my_1.png">
          <span class="tool_text">我的订单</span>
        </van-grid-item>
        <van-grid-item @click="coupon">
          <img class="tool_icon" src="@/assets/images/my_2.png">
          <span class="tool_text">优惠券</span>
        </van-grid-item>
        <van-grid-item @click="invitation">
          <img class="tool_icon" src="@/assets/images/my_3.png">
          <span class="tool_text">我的邀请</span>
        </van-grid-item>
        <!-- <van-grid-item @click="QRcode">
              <img class="tool_icon" src="@/assets/images/my_4.png" />
              <span class="tool_text">我的二维码</span>
        </van-grid-item> -->
        <van-grid-item @click="address">
          <img class="tool_icon" src="@/assets/images/loca.png">
          <span class="tool_text">我的地址</span>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="user_upgrade" @click="member" />
    <!-- <div class="user_tool">
      <div class="tool_title">常用工具</div>
      <van-grid :border="false" :column-num="4">
          <van-grid-item v-for="(item,index) in toollist" :key="index" :to="item.url">
            <img class="tool_icon" :src="item.icon" />
            <span class="tool_text">{{ item.test }}</span>
          </van-grid-item>
      </van-grid>
    </div> -->
    <!-- <div class="user_tool">
      <div class="tool_title">三方权益</div>
      <van-grid :border="false" :column-num="4">
          <van-grid-item v-for="(item,index) in thirdlist" :key="index" :to="item.url">
            <img class="tool_icon" :src="item.icon" />
            <span class="tool_text">{{ item.test }}</span>
          </van-grid-item>
      </van-grid>
    </div> -->
    <Dowyuetao :second="second" @closepop="isshow" />
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from '@/component/Tabbar'
import Dowyuetao from '@/component/dowyuetao'
import Vue from 'vue'
import { DropdownItem, DropdownMenu, Grid, GridItem, Icon, Image as VanImage, NavBar, Toast } from 'vant'

Vue.use(NavBar).use(VanImage).use(DropdownMenu).use(DropdownItem).use(Icon).use(Toast).use(Grid).use(GridItem)
import {
  getusernew
} from '@/services/userApi'
import { newMembershipLevelList } from '@/services/goods'
import { copyText } from '@/utils/common/utils'

export default {
  components: {
    Tabbar,
    Dowyuetao
  },
  data() {
    return {
      memberInfo: '',
      second: false,
      userarvr: '',
      username: '',
      levelText: '',
      codeFand: '',
      saveMoney: '',
      ico: '',
      usedMoney: '',
      leftCardNum: '',
      toollist: [
        { icon: require('@/assets/images/my_tool_0.png'), test: '我的店铺', url: '/mywallet' },
        { icon: require('@/assets/images/my_tool_1.png'), test: '积分商城' },
        { icon: require('@/assets/images/my_tool_2.png'), test: '在线客服' },
        { icon: require('@/assets/images/my_tool_3.png'), test: '浏览记录', url: '/Browsinghistory' },
        { icon: require('@/assets/images/my_tool_7.png'), test: '完善信息', url: '/perinfo' },
        { icon: require('@/assets/images/my_tool_8.png'), test: '实体绑卡' },
        { icon: require('@/assets/images/my_tool_4.png'), test: '我的收藏', url: '/myCollection' },
        { icon: require('@/assets/images/my_tool_5.png'), test: '领券中心' },
        { icon: require('@/assets/images/my_tool_6.png'), test: '培训师' }
      ],
      thirdlist: [
        { icon: require('@/assets/images/my_third_1.png'), test: '好大夫' },
        { icon: require('@/assets/images/my_third_2.png'), test: '省钱加油站', url: '/refueling-list' },
        { icon: require('@/assets/images/my_third_3.png'), test: '网红别墅' },
        { icon: require('@/assets/images/my_third_4.png'), test: '滴滴加油充电' },
        { icon: require('@/assets/images/my_third_5.png'), test: '家政家务' }
      ]
    }
  },
  created() {

  },
  mounted() {
    this.getuserinfo()
    this.newMembershipLevelList()
  },
  methods: {
    newMembershipLevelList() {
      const body = {
        uid: localStorage.getItem('uid') || ''
      }
      newMembershipLevelList(body).then((res) => {
        if (Number(res.code) === 200) {
          this.memberInfo = res.data.memberInfo
        }
      })
    },

    isshow() {
      this.second = !this.second
    },
    // 个人信息查询
    getuserinfo() {
      const _self = this
      getusernew().then(res => {
        if (Number(res.code) === 200) {
          _self.userarvr = res.data.avatar
          _self.username = res.data.nickName
          _self.levelText = res.data.levelText
          _self.codeFand = res.data.reCode
          _self.saveMoney = res.data.saveMoney
          _self.ico = res.data.ico
          _self.usedMoney = res.data.balanceMoney
          _self.leftCardNum = res.data.leftCardNum
        } else {
          Toast(res.msg)
        }
      })
    },
    // 复制
    copyCode() {
      const orderNumber = this.codeFand || ''
      copyText(orderNumber, () => {
        Toast('复制成功')
      })
    },
    // 升级会员
    member() {
      this.$router.push({ path: '/MustBuy' })
    },
    wallet() {
      this.$router.push({ path: '/mywallet' })
    },
    // 设置
    setup() {
      this.$router.push({ path: '/setup' })
    },
    invitation() {
      this.$router.push({ path: '/myinvitation' })
    },
    coupon() {
      this.$router.push({ path: '/coupon' })
    },
    Yuedou() {
      this.$router.push({ path: '/myYuedou' })
    },
    QRcode() {
      this.$router.push({ path: '/QRcode' })
    },
    perinfo() {
      this.$router.push({ path: '/perinfo' })
    },
    myorder() {
      this.$router.push({ path: '/myorder' })
    },
    address() {
      this.$router.push({ path: '/addressList', query: { id: this.$store.state.addressInfo.id }})
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .user_information {
    padding: 0 13px 20px;
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
    .information_s {
      margin-left: 20px;
      font-size: 11px;
      color: #333333;

      img {
        width: 17px;
        height: 17px;
        display: block;
        margin: 3px auto;
      }
    }
  }

  .user_init {
    padding: 30px 20px 0;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user_head {
      width: 62px;
      height: 62px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border: 2px solid #F6D697;
        border-radius: 50%;
      }
    }

    .user_name {
      color: #333333;
      font-size: 19px;
      padding-left: 10px;

      .user_na {
        display: flex;
        align-items: center;

        & > div:nth-child(1) {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap
        }

        .user_vpi {
          display: flex;
          align-items: center;
          margin-left: 10px;
          background: #2C2A29;
          color: #DCDCDC;
          font-size: 11px;
          padding: 3px 7px;
          border-radius: 15px;
          direction: rtl;

          img {
            width: 17px;
            height: 17px;
            //margin-left: 5px;
          }
        }
      }

      .user_id {
        margin-top: 15px;
        color: #999999;
        font-size: 13px;

        .copy {
          font-size: 12px;
          margin-left: 10px;
          color: #999999;
          border: 1px solid #999999;
          border-radius: 5px;
          padding: 2px 3px;
        }
      }

      .Sign_in {
        position: absolute;
        right: 0;
        top: 12%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        background-image: linear-gradient(-90deg, #EF773F, #EC4B46, #FC977E);
        font-size: 12px;
        padding: 2px 5px 2px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;

        img {
          width: 19px;
          height: 19px;
          margin-right: 8px;
        }
      }
    }
  }

  .upgrade {
    width: 335px;
    height: 37px;
    margin: auto;
    background-image: url('~@/assets/images/up_bg.png');
    background-size: 100%, 100%;
    background-repeat: no-repeat;
    background-position-y: 1px;
  }

  .bill {
    display: flex;
    align-items: center;
    background-image: linear-gradient(-90deg, #FF5457, #F4023D, #FD6B2B);
    font-family: Bahnschrift Regular, Bahnschrift Regular-Regular;
    font-weight: 500;
    margin: 0 13px;
    padding: 25px 10px;
    border-radius: 7px;

    .bill_item {
      flex: 3;
      color: #FFFFFF;

      & > div:nth-child(1) {
        font-size: 21px;
        margin-bottom: 10px;
      }

      & > div:nth-child(2) {
        font-size: 11px;
      }
    }
  }

  .user_my {
    margin: 13px 13px 10px;
    background: #FFFFFF;
    border-radius: 7px;
    padding: 17px 10px;
    display: flex;
    align-items: center;

    & > div {
      flex: 3;

      & > span:nth-child(2) {
        margin-top: 10px;
        font-size: 11px;
        color: #333333;
        display: block;
      }

      img {
        width: 27px;
        height: 27px;
        display: block;
        margin: auto;
      }
    }
  }

  .user_upgrade {
    margin: 10px 13px 10px;
    height: 69px;
    background-image: url('~@/assets/images/my_5.png');
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }

  .user_tool {
    margin: 10px 13px 10px;
    border-radius: 7px;
    background: #FFFFFF;
    padding: 10px;

    .tool_title {
      color: #333333;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
    }

    .tool_icon {
      width: 27px;
      height: 27px;
      margin-bottom: 7px;
    }

    .tool_text {
      color: #333333;
      font-size: 11px;
    }
  }
}
</style>
